import Sider from 'antd/lib/layout/Sider'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { Menu } from 'antd'
import { Link, useHistory } from 'react-router-dom'
import { routes } from '../../../constants/config'
import { ReactComponent as LogoIcon } from '../../../assets/icons/logo.svg'
import styles from './Aside.module.scss'

const { SubMenu } = Menu

type Props = {}

const Aside: FC<Props> = () => {
    const route = useHistory().location.pathname

    const [key, setKey] = useState(['sub0'])
    const [item, setItem] = useState(['sub0'])

    const setOpenMenu = useCallback(() => {
        switch (route) {
            case routes.advertising:
                setKey(['sub0'])
                setItem(['sub0_1'])
                break

            case routes.get_ads:
                setKey(['sub0'])
                setItem(['sub0_2'])
                break

            case routes.article_category:
                setKey(['sub1'])
                setItem(['sub1_1'])
                break

            case routes.get_article_categories:
                setKey(['sub1'])
                setItem(['sub1_2'])
                break

            case routes.news_category:
                setKey(['sub2'])
                setItem(['sub2_1'])
                break

            case routes.get_news_categories:
                setKey(['sub2'])
                setItem(['sub2_2'])
                break

            case routes.create_location_type:
                setKey(['sub3'])
                setItem(['sub3_1'])
                break

            case routes.get_location_types:
                setKey(['sub3'])
                setItem(['sub3_2'])
                break

            case routes.event_category:
                setKey(['sub4'])
                setItem(['sub4_1'])
                break

            case routes.get_event_categories:
                setKey(['sub4'])
                setItem(['sub4_2'])
                break

            case routes.create_events:
                setKey(['sub5'])
                setItem(['sub5_1'])
                break

            case routes.get_events_list:
                setKey(['sub5'])
                setItem(['sub5_2'])
                break

            case routes.feedbacksEvents.split('?')[0]:
                setKey(['sub5'])
                setItem(['sub5_3'])
                break

            case routes.comparison_categories:
                setKey(['sub5'])
                setItem(['sub5_4'])
                break

            case routes.comparison_places:
                setKey(['sub5'])
                setItem(['sub5_5'])
                break

            case routes.create_articles:
                setKey(['sub6'])
                setItem(['sub6_1'])
                break

            case routes.get_article_list:
                setKey(['sub6'])
                setItem(['sub6_2'])
                break

            case routes.create_news:
                setKey(['sub7'])
                setItem(['sub7_1'])
                break

            case routes.get_news_list:
                setKey(['sub7'])
                setItem(['sub7_2'])
                break

            case routes.create_location:
                setKey(['sub8'])
                setItem(['sub8_1'])
                break

            case routes.get_location_list:
                setKey(['sub8'])
                setItem(['sub8_2'])
                break

            case routes.create_social_network:
                setKey(['sub8'])
                setItem(['sub8_3'])
                break

            case routes.special_projects:
                setKey(['sub10'])
                setItem(['sub10_1'])
                break

            case routes.get_special_projects:
                setKey(['sub10'])
                setItem(['sub10_2'])
                break
            case routes.cities:
                setKey(['sub11'])
                setItem(['sub11_1'])
                break
            case routes.editCities:
                setKey(['sub11'])
                setItem(['sub11_2'])
                break
            case routes.requests:
                setKey(['sub12'])
                setItem(['sub12'])
                break

            default:
                break
        }
    }, [route])

    const changeMenu = (i: string) => {
        if (key.includes(i)) {
            setKey([])
        } else {
            setKey([i])
        }
    }
    const changeMenuItem = (i: string) => {
        setItem([i])
    }

    useEffect(() => {
        setOpenMenu()
    }, [route, setOpenMenu])

    return (
        <Sider
            style={{
                overflow: 'auto',
                height: '100vh',
                position: 'fixed',
                left: 0,
                top: 0,
                bottom: 0,
            }}
            width={270}
        >
            <div className={styles.logoWrapper}>
                <LogoIcon className={styles.logo} />
            </div>
            <Menu selectedKeys={item} openKeys={key} theme="dark" mode="inline">
                <SubMenu
                    onTitleClick={() => changeMenu('sub0')}
                    title="Реклама"
                    key="sub0"
                >
                    <Menu.Item
                        onClick={() => changeMenuItem('sub0_1')}
                        key="sub0_1"
                    >
                        <Link to={routes.advertising}>
                            Добавить рекламную запись
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        onClick={() => changeMenuItem('sub0_2')}
                        key="sub0_2"
                    >
                        <Link to={routes.get_ads}>Получить всю рекламу</Link>
                    </Menu.Item>
                </SubMenu>
                <SubMenu
                    onTitleClick={() => changeMenu('sub1')}
                    title="Категории статей"
                    key="sub1"
                >
                    <Menu.Item
                        onClick={() => changeMenuItem('sub1_1')}
                        key="sub1_1"
                    >
                        <Link to={routes.article_category}>
                            Создать категорию статей
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        onClick={() => changeMenuItem('sub1_2')}
                        key="sub1_2"
                    >
                        <Link to={routes.get_article_categories}>
                            Получить список категорий
                        </Link>
                    </Menu.Item>
                </SubMenu>
                <SubMenu
                    onTitleClick={() => changeMenu('sub2')}
                    title="Категории новостей"
                    key="sub2"
                >
                    <Menu.Item
                        onClick={() => changeMenuItem('sub2_1')}
                        key="sub2_1"
                    >
                        <Link to={routes.news_category}>
                            Создать категорию новостей
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        onClick={() => changeMenuItem('sub2_2')}
                        key="sub2_2"
                    >
                        <Link to={routes.get_news_categories}>
                            Получить список категорий
                        </Link>
                    </Menu.Item>
                </SubMenu>
                <SubMenu
                    onTitleClick={() => changeMenu('sub3')}
                    title="Типы локаций"
                    key="sub3"
                >
                    <Menu.Item
                        onClick={() => changeMenuItem('sub3_1')}
                        key="sub3_1"
                    >
                        <Link to={routes.create_location_type}>
                            Создание типа локации
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        onClick={() => changeMenuItem('sub3_2')}
                        key="sub3_2"
                    >
                        <Link to={routes.get_location_types}>
                            Получить список локаций
                        </Link>
                    </Menu.Item>
                </SubMenu>

                <SubMenu
                    onTitleClick={() => changeMenu('sub4')}
                    title="Категории событий"
                    key="sub4"
                >
                    <Menu.Item
                        onClick={() => changeMenuItem('sub4_1')}
                        key="sub4_1"
                    >
                        <Link to={routes.event_category}>
                            Категория событий
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        onClick={() => changeMenuItem('sub4_2')}
                        key="sub4_2"
                    >
                        <Link to={routes.get_event_categories}>
                            Получить список категорий
                        </Link>
                    </Menu.Item>
                </SubMenu>

                <SubMenu
                    onTitleClick={() => changeMenu('sub5')}
                    title="События"
                    key="sub5"
                >
                    <Menu.Item
                        onClick={() => changeMenuItem('sub5_1')}
                        key="sub5_1"
                    >
                        <Link to={routes.create_events}>Создание событий</Link>
                    </Menu.Item>

                    <Menu.Item
                        onClick={() => changeMenuItem('sub5_2')}
                        key="sub5_2"
                    >
                        <Link to={routes.get_events_list}>
                            Получить список событий
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        onClick={() => changeMenuItem('sub5_3')}
                        key="sub5_3"
                    >
                        <Link to={routes.feedbacksEvents}>Отзывы</Link>
                    </Menu.Item>
                    <Menu.Item
                        onClick={() => changeMenuItem('sub5_4')}
                        key="sub5_4"
                    >
                        <Link to={routes.comparison_categories}>
                            Сопоставление категорий
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        onClick={() => changeMenuItem('sub5_5')}
                        key="sub5_5"
                    >
                        <Link to={routes.comparison_places}>
                            Сопоставление площадок проведения
                        </Link>
                    </Menu.Item>
                </SubMenu>
                <SubMenu
                    onTitleClick={() => changeMenu('sub6')}
                    title="Статьи"
                    key="sub6"
                >
                    <Menu.Item
                        onClick={() => changeMenuItem('sub6_1')}
                        key="sub6_1"
                    >
                        <Link to={routes.create_articles}>Создание статей</Link>
                    </Menu.Item>
                    <Menu.Item
                        onClick={() => changeMenuItem('sub6_2')}
                        key="sub6_2"
                    >
                        <Link to={routes.get_article_list}>
                            Получить список статей
                        </Link>
                    </Menu.Item>
                </SubMenu>

                <SubMenu
                    onTitleClick={() => changeMenu('sub7')}
                    title="Новости"
                    key="sub7"
                >
                    <Menu.Item
                        onClick={() => changeMenuItem('sub7_1')}
                        key="sub7_1"
                    >
                        <Link to={routes.create_news}>Создание новости</Link>
                    </Menu.Item>
                    <Menu.Item
                        onClick={() => changeMenuItem('sub7_2')}
                        key="sub7_2"
                    >
                        <Link to={routes.get_news_list}>
                            Получить список новостей
                        </Link>
                    </Menu.Item>
                </SubMenu>

                <SubMenu
                    onTitleClick={() => changeMenu('sub8')}
                    title="Места"
                    key="sub8"
                >
                    <Menu.Item
                        onClick={() => changeMenuItem('sub8_1')}
                        key="sub8_1"
                    >
                        <Link to={routes.create_location}>Создание места</Link>
                    </Menu.Item>
                    <Menu.Item
                        onClick={() => changeMenuItem('sub8_2')}
                        key="sub8_2"
                    >
                        <Link to={routes.get_location_list}>
                            Получить список мест
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        onClick={() => changeMenuItem('sub8_3')}
                        key="sub8_3"
                    >
                        <Link to={routes.create_social_network}>
                            Социальные сети
                        </Link>
                    </Menu.Item>
                </SubMenu>
                <SubMenu
                    onTitleClick={() => changeMenu('sub10')}
                    title="Специальные проекты"
                    key="sub10"
                >
                    <Menu.Item
                        onClick={() => changeMenuItem('sub10_1')}
                        key="sub10_1"
                    >
                        <Link to={routes.special_projects}>Создать Проект</Link>
                    </Menu.Item>
                    <Menu.Item
                        onClick={() => changeMenuItem('sub10_2')}
                        key="sub10_2"
                    >
                        <Link to={routes.get_special_projects}>
                            Получить список событий
                        </Link>
                    </Menu.Item>
                </SubMenu>
                <SubMenu
                    onTitleClick={() => changeMenu('sub11')}
                    title="Города"
                    key="sub11"
                >
                    <Menu.Item
                        onClick={() => changeMenuItem('sub11_1')}
                        key="sub11_1"
                    >
                        <Link to={routes.cities}>Список городов</Link>
                    </Menu.Item>
                    <Menu.Item
                        onClick={() => changeMenuItem('sub11_2')}
                        key="sub11_2"
                    >
                        <Link to={routes.editCities}>Добавить город</Link>
                    </Menu.Item>
                </SubMenu>
                <Menu.Item
                    onClick={() => changeMenuItem('sub12')}
                    title="Заявки"
                    key="sub12"
                >
                    <Link to={routes.requests}>Заявки</Link>
                </Menu.Item>
                <Menu.Item
                    onClick={() => changeMenuItem('sub13')}
                    title="Пользователи"
                    key="sub13"
                >
                    <Link to={routes.get_users}>Пользователи</Link>
                </Menu.Item>
            </Menu>
        </Sider>
    )
}

export default Aside
