import 'moment/locale/ru'
import { FC, memo, useState } from 'react'
import style from './CategoryItem.module.scss'
import {
    IKassyCatsCashRu,
    IKassyCatsPlacesRu,
} from '../../../services/ComparisonCategoriesApi'
import { Select } from 'antd'
import { DefaultOptionType } from 'antd/lib/select'

export type OnSelectTypeCategory = {
    item: IKassyCatsCashRu | IKassyCatsPlacesRu // категория кассы ру
    id: number // минкультр категория
}

type Props = {
    item: IKassyCatsCashRu | IKassyCatsPlacesRu
    options: DefaultOptionType[]
    onSelect: (v: OnSelectTypeCategory) => void
}

const CategoryItem: FC<Props> = ({ item, options, onSelect }) => {
    const [value, setValue] = useState<number>()

    const onSelectHandler = (id: number) => {
        setValue(id)
        onSelect({ id, item })
    }

    return (
        <li className={style.cat}>
            <div className={style.row}>
                <p>{item.kassy_name}</p>
            </div>
            <div className={style.row}>
                <Select
                    options={options}
                    style={{ width: '100%' }}
                    value={value}
                    placeholder="Выбрать категорию"
                    onChange={onSelectHandler}
                />
            </div>
        </li>
    )
}

export default memo(CategoryItem)
