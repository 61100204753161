import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/react'
import { AxiosError, AxiosRequestConfig } from 'axios'
import api from '../../utils/api'

export const axiosBaseQuery =
    (
        { baseUrl }: { baseUrl: string } = { baseUrl: '' }
    ): BaseQueryFn<
        {
            url: string
            method: AxiosRequestConfig['method']
            data?: AxiosRequestConfig['data']
            params?: AxiosRequestConfig['params']
            headers?: AxiosRequestConfig['headers']
        },
        unknown,
        unknown
    > =>
    async ({ url, method, data, params, headers }) => {
        try {
            const result = await api({
                url: baseUrl + url,
                method,
                data,
                params,
                headers,
            })

            // console.log('😊 axiosresult', result)

            return { data: result.data }
        } catch (axiosError) {
            const error = axiosError as AxiosError

            // console.log('😡 axios error', error)
            // console.log("axiosError", JSON.stringify(err, null, 2));

            return {
                error: {
                    status: error?.response?.status,
                    data: error?.response?.data || error?.message,
                    meta: {
                        // дополнительная информация об ошибке, если необходимо
                    },
                },
            }
        }
    }
