export const downloadImage = async (url: string, filename: string) => {
    try {
        const response = await fetch(url, { mode: 'cors' }) // Загружаем файл
        const blob = await response.blob() // Преобразуем в Blob
        const blobUrl = URL.createObjectURL(blob) // Создаём временную ссылку

        const link = document.createElement('a')
        link.href = blobUrl
        link.download = filename
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        URL.revokeObjectURL(blobUrl) // Очищаем память
    } catch (error) {
        console.error('Ошибка загрузки изображения:', error)
    }
}
