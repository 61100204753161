import {
    CreateEventResponseType,
    DataUploadPhoto,
    MetaType,
    PhotoResponseType,
    RemovePhotoResponseType,
} from './../types/types'
import { getFormData } from './../constants/helper'
import { ErrorsTypes } from '../utils/api'
import api from '../utils/api'
import { endpoints } from '../constants/constants'
import { SpecialProjectResponseType } from '../types/types'
import { route } from '../utils/route'

export type SpecialProjectData = {
    success: boolean
    errors?: ErrorsTypes
    data?: {
        specialProject: SpecialProjectResponseType
    }
}

type DataCreateSpecialProject = {
    title: string
    content: string
    links: string
    description: string
}

export type GetListSpecialProjectResponse = {
    data: SpecialProjectResponseType[]
    baseUrl: string
    errors: any
    systemVersion: number
    needUpdate: number
    success: boolean
    meta: MetaType
}

export type SpecialProjectResponse = {
    data: SpecialProjectResponseType
    baseUrl: string
    errors: string[]
    systemVersion: number
    needUpdate: number
    success: boolean
    meta: {
        totalCount: number
    }
}

type ResponseUploadPhototype = {
    data: PhotoResponseType
    success: boolean
}

/**
 * Сервис для работы со Специальными проектами
 */
export class SpecialProject {
    /**
     * Метод для получения id создаваемого специального проекта
     */
    static async createSpecialProject(): Promise<CreateEventResponseType> {
        const response = await api.get<SpecialProjectResponse>(
            endpoints.createSpecialProject
        )
        return response.data.data
    }

    /**
     * Метод для для публикации специального проекта
     */
    static async unPublishSpecialProject(id: number): Promise<boolean> {
        const response = await api.get<SpecialProjectResponse>(
            `${endpoints.unPublishSpecialProject}/${id}`
        )

        return response.data.success
    }

    /**
     * Метод для отмены публикации события
     */
    static async publishSpecialProject(id: number): Promise<boolean> {
        const response = await api.get<SpecialProjectResponse>(
            `${endpoints.publishSpecialProject}/${id}`
        )

        return response.data.success
    }

    /**
     * Метод для получения списка специальных проектов
     */

    static async getListSpecialProject(
        page?: number,
        onPage?: number,
        limit: number = 10
    ): Promise<GetListSpecialProjectResponse> {
        const offset = (page ? page - 1 : 0) * (onPage ? onPage : 10)
        const lim = `&limit=${limit}`
        const response = await api.get<GetListSpecialProjectResponse>(
            `${endpoints.listSpecialProject}?offset=${offset}${lim}`
        )

        return response.data
    }
    /**
     * Метод для получения списка специальных проектов
     */

    static async getOneProject(
        id: number
    ): Promise<SpecialProjectResponseType> {
        const response = await api.get(
            route(endpoints.getSpecialProject, { id })
        )
        return response.data.data
    }

    /**
     * Метод для отправки фотографий контента
     */

    static async uploadPhoto(
        id: number,
        data: DataUploadPhoto
    ): Promise<ResponseUploadPhototype> {
        const response = await api.post(
            `${endpoints.uploadPhotoSpecialProject}/${id}`,
            getFormData(data, true)
        )
        return response.data
    }

    /**
     * Метод для удаления фотографии  специального проекта
     */

    static async removePhoto(id: number): Promise<boolean> {
        const response = await api.delete<RemovePhotoResponseType>(
            `${endpoints.removePhotoSpecialProject}/${id}`
        )

        return response.data.success
    }

    /**
     * Метод удаления специального проекта
     */

    static async deleteSpecialProject(id: number): Promise<boolean> {
        const response = await api.delete(
            `${endpoints.deleteSpecialProject}/${id}`
        )

        return response.data.data
    }

    /**
     * Запрос на создание нового специального проекта
     * @param title  Название заголовка
     * @param content  Описание специального проекта
     * @param links  Ссылка на специальный проект
     * @param photo Фотографии специального проекта
     */

    static async updateSpecialProject(
        id: number,
        data: DataCreateSpecialProject
    ): Promise<SpecialProjectData> {
        const result: SpecialProjectData = {
            success: false,
        }

        try {
            const response = await api.post<SpecialProjectResponseType>(
                `${endpoints.updateSpecialProject}/${id}`,
                getFormData(data)
            )

            result.success = true
            result.data = {
                specialProject: response.data,
            }
        } catch (error: any) {
            console.log('error', error.response)
        }
        return result
    }
}
