import 'moment/locale/ru'
import { FC, useMemo, useState } from 'react'
import Title from 'antd/lib/typography/Title'
import style from './ComparisonCategories.module.scss'
import { Button } from 'antd'
import {
    DataSaveCategories,
    useGetCategoriesCashRuQuery,
    useGetCategoriesPlacesRuQuery,
    useSaveCatsCategoriesMutation,
    useSavePlacesCategoriesMutation,
} from '../../../services/ComparisonCategoriesApi'
import CategoryItem, {
    OnSelectTypeCategory,
} from '../CategoryItem/CategoryItem'
import { Loader } from '../../simples/Loader'

type Props = {
    isPlaces?: boolean
}

const ComparisonCategories: FC<Props> = ({ isPlaces }) => {
    const { data: categories, isLoading: isLoadingCategoties } =
        useGetCategoriesCashRuQuery()
    const { data: places, isLoading: isLoadingPlaces } =
        useGetCategoriesPlacesRuQuery()

    const [savePlacesCategories, { isLoading: isLoadingSavePlaces }] =
        useSavePlacesCategoriesMutation()

    const [saveCatsCategories, { isLoading: isLoadingSaveCats }] =
        useSaveCatsCategoriesMutation()

    const [selects, setSelects] = useState<OnSelectTypeCategory[]>([])

    const isLoading = useMemo(() => {
        return isLoadingCategoties || isLoadingPlaces
    }, [isLoadingCategoties, isLoadingPlaces])

    const isLoadingSave = useMemo(() => {
        return isLoadingSavePlaces || isLoadingSaveCats
    }, [isLoadingSavePlaces, isLoadingSaveCats])

    const optionsList = useMemo(() => {
        if (isPlaces) {
            return (
                places?.data.minkult_places.map((item) => {
                    return {
                        label: item.name,
                        value: item.id,
                    }
                }) || []
            )
        } else {
            return (
                categories?.data.minkult_cats.map((item) => {
                    return {
                        label: item.name,
                        value: item.id,
                    }
                }) || []
            )
        }
    }, [categories, places, isPlaces])

    const categoriesData = useMemo(() => {
        if (isPlaces) {
            return places?.data.kassy_cats || []
        } else {
            return categories?.data.kassy_cats || []
        }
    }, [categories, places, isPlaces])

    const onSelect = (data: OnSelectTypeCategory) => {
        setSelects((prev) => {
            const isItem = prev.find((it) => it.item.id === data.item.id)

            if (isItem) {
                return prev.map((it) => {
                    if (it.item.id === data.item.id) {
                        return data
                    }

                    return it
                })
            }

            return [...prev, data]
        })
    }

    const onSave = () => {
        const sendData: DataSaveCategories[] = selects.map((it) => {
            return {
                minkult_id: it.id,
                kassy_id: it.item.kassy_id,
            }
        })

        if (!sendData.length) return

        if (isPlaces) {
            savePlacesCategories(sendData)
        } else {
            saveCatsCategories(sendData)
        }
    }

    return (
        <>
            <Title level={2}>
                {isPlaces
                    ? 'Сопоставление площадок проведения'
                    : 'Сопоставление категорий'}
            </Title>

            {!!categoriesData.length && (
                <>
                    <div className={style.titles}>
                        <div className={style.titleBlock}>
                            <Title level={5}>Категории кассы ру</Title>
                        </div>
                        <div className={style.titleBlock}>
                            <Title level={5}>Минкульт категории</Title>
                        </div>
                    </div>

                    <ul className={style.list}>
                        {categoriesData.map((it) => {
                            return (
                                <CategoryItem
                                    onSelect={onSelect}
                                    options={optionsList}
                                    item={it}
                                    key={it.id}
                                />
                            )
                        })}
                    </ul>
                </>
            )}

            {!categoriesData.length && !isLoading && (
                <div>
                    <p className={style.emptyText}>
                        {isPlaces ? 'Новых площадок' : 'Новых категорий'} для
                        сопоставления не найдено
                    </p>
                </div>
            )}

            {isLoading && (
                <div className={style.loader}>
                    <Loader />
                </div>
            )}

            {!!categoriesData.length && (
                <Button type="primary" onClick={onSave}>
                    {!isLoadingSave ? 'Сохранить' : <Loader />}
                </Button>
            )}
        </>
    )
}

export default ComparisonCategories
