import { endpoints } from '../constants/constants'
import { baseApi } from './RTK/baseApi'

export interface IKassyCatsCashRu {
    categoty_id: null
    id: number
    kassy_id: number
    kassy_name: string
}

export interface IMinkultCats {
    createdAt: string
    description: string
    id: number
    name: string
    updatedAt: string
}

export interface IKassyCatsPlacesRu {
    id: number
    kassy_address: string
    kassy_id: number
    kassy_name: string
    place_id: null
}

export interface IMinkultPlacesPlacesRu {
    address: string
    id: number
    name: string
}

export interface ICategoriesCashRu {
    kassy_cats: IKassyCatsCashRu[]
    minkult_cats: IMinkultCats[]
}

export interface ICategoriesPlacesRu {
    kassy_cats: IKassyCatsPlacesRu[]
    minkult_places: IMinkultPlacesPlacesRu[]
}

export type DataSaveCategories = {
    minkult_id: number
    kassy_id: number
}

type Response<T> = {
    data: T
}

export const ComparisonCategoriesApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getCategoriesCashRu: builder.query<Response<ICategoriesCashRu>, void>({
            query: () => ({
                url: endpoints.cashCategories,
                method: 'GET',
            }),
            providesTags: ['categories-cash-ru'],
        }),
        getCategoriesPlacesRu: builder.query<
            Response<ICategoriesPlacesRu>,
            void
        >({
            query: () => ({
                url: endpoints.placesCategories,
                method: 'GET',
            }),
            providesTags: ['categories-places-ru'],
        }),
        savePlacesCategories: builder.mutation<void, DataSaveCategories[]>({
            query: (data) => ({
                url: endpoints.savePlacesCategories,
                method: 'POST',
                data,
            }),
            invalidatesTags: ['categories-places-ru'],
        }),
        saveCatsCategories: builder.mutation<void, DataSaveCategories[]>({
            query: (data) => ({
                url: endpoints.saveСatsCategories,
                method: 'POST',
                data,
            }),
            invalidatesTags: ['categories-cash-ru'],
        }),
    }),
})

export const {
    useGetCategoriesCashRuQuery,
    useGetCategoriesPlacesRuQuery,
    useSavePlacesCategoriesMutation,
    useSaveCatsCategoriesMutation,
} = ComparisonCategoriesApi
