import 'moment/locale/ru'
import JoditEditor from 'jodit-react'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import Title from 'antd/lib/typography/Title'
import locale from 'antd/lib/locale/ru_RU'
import moment, { Moment } from 'moment'
import produce from 'immer'
import style from './CreateEvent.module.scss'
import {
    Alert,
    Button,
    Checkbox,
    Collapse,
    ConfigProvider,
    DatePicker,
    InputNumber,
    message,
} from 'antd'
import { Select } from 'antd'
import { List } from 'antd'
import { useHistory } from 'react-router'
import { routes } from '../../../constants/config'
import {
    DOMAIN,
    PhotoGaleryType,
    photoSizes,
} from '../../../constants/constants'
import { CategoryEvent } from '../../../services/CategoryEvent'
import { Event as EventService } from '../../../services/Event'
import { Location } from '../../../services/Location'
import { LocationTypeResponse } from '../../../services/LocationTypes'
import {
    ActorsType,
    CategoryResponseType,
    EventResponseType,
    InterestingFactsType,
    IPhotoArea,
    PhotoResponseType,
    PhotoType,
    SeanceType,
    TextEditorType,
} from '../../../types/types'
import { SaveBtnBlock } from '../../simples/SaveBtnBlock'
import { TitleAndDefinition } from '../../simples/TitleAndDefinition'
import { PhotoGallery } from '../PhotoGallery'
import { TextEditor } from '../TextEditor'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { PhotoArea } from '../PhotoArea'
import { isChecked, isMainPhoto } from '../../../utils/photoEditor'
import { LoadFiles } from '../../../services/FileService'
import { downloadImage } from '../../../utils/dowloadImage'

const { Panel } = Collapse
const { RangePicker } = DatePicker
const buttons = ['bold', 'underline', 'italic', '|', 'fontsize', 'link']
const formatTime = 'DD-MM-YYYY HH:mm'
const config = {
    readonly: false,
    addNewLineOnDBLClick: false,
    toolbar: true,
    spellcheck: true,
    language: 'ru',
    toolbarButtonSize: 'medium',
    toolbarAdaptive: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    askBeforePasteHTML: true,
    askBeforePasteFromWord: true,
    buttons: buttons,
    width: '100%',
    height: '100%',
    FontSizer: 24,
    tabIndex: 1,
    enter: 'P',
    uploader: {
        insertImageAsBase64URI: false,
    },
}

const CreateEvent: FC = () => {
    const eventID = useHistory().location.search?.split('=')[1]
    const nav = useHistory()

    const [event, setEvent] = useState<EventResponseType>()

    const [publish, setPublish] = useState(false)
    const [categoryId, setCategoryId] = useState<number | undefined>()
    const [placeId, setPlaceId] = useState<number | undefined>()
    const [name, setName] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [youtubeLink, setYoutubeLink] = useState<string>('')
    const [buyTicketLink, setBuyTicketLink] = useState<string>('')
    const [priceMin, setPriceMin] = useState<number>(0)
    const [priceMax, setPriceMax] = useState<number>(0)
    const [dates, setDates] = useState<Moment[] | null>(null)
    const [schedule, setSchedule] = useState<any[]>([])
    const [seances, setSeances] = useState<SeanceType[]>([])
    const [currentSeanseDate, setCurrentSeanseDate] = useState()
    const [isError, setError] = useState(false)
    const [eventList, setEventList] = useState<CategoryResponseType[]>([])
    const [locationList, setLocationList] = useState<LocationTypeResponse[]>([])
    const [details, setDetails] = useState<string>('')
    const [pushkinCard, setPushkinCard] = useState(false)
    const [isTop, setIsTop] = useState<0 | 1>(0)
    const [registrationOpened, setRegistrationOpened] = useState<0 | 1>(0)
    const [pictureData, setPictureData] = useState<PhotoResponseType[]>([])
    const [newphotos, setNewPhotos] = useState<IPhotoArea[]>([])
    const [createEvent_ID, setCreateEvent_ID] = useState<number>(
        eventID ? +eventID : 0
    )
    const [picturePreviewData, setPicturePreviewData] = useState<
        PhotoResponseType[]
    >([])
    const [interestingFacts, setInterestingFacts] = useState<
        InterestingFactsType[]
    >([{ description: '' }])
    const [content, setContent] = useState<Array<TextEditorType>>([
        {
            id: new Date().getTime(),
            text: '',
            photos: [],
        },
    ])
    const [actors, setActors] = useState<ActorsType[]>([
        {
            role: '',
            actors: '',
        },
    ])
    const [tiflo, setTiflo] = useState(0)

    const handleSelectMainPhoto = (id: number, type: PhotoType) => {
        setNewPhotos((prev) =>
            prev.map((photo) =>
                photo.photo_id === id && photo.type === type
                    ? { ...photo, is_main: true }
                    : photo.photo_id !== id && photo.type === type
                    ? { ...photo, is_main: false }
                    : photo
            )
        )
    }

    /**
     * получаем мероприятие по id
     */
    const getEvent = useCallback(async () => {
        const response = await EventService.getEvent(+eventID)

        setEvent(response)
        setBuyTicketLink(response.buyTicketLink || '')
        setPlaceId(response.placeId)
        setName(response.name)
        setTiflo(response.tiflo)
        setDescription(response.description)
        setYoutubeLink(response.youtubeLink || '')
        setContent(response.content)
        setPriceMin(response.priceMin || 0)
        setPriceMax(response.priceMax || 0)
        setInterestingFacts(
            response.interestingFacts.length > 0
                ? response.interestingFacts
                : [{ description: '' }]
        )
        setPictureData(response.photos)
        setPicturePreviewData(response.previews)
        setDates(
            getMoment({
                startAt: response.startAt,
                endAt: response.endAt,
            })
        )
        setSeances(response.seances)

        setCategoryId(response.categoryId)
        setActors(
            response.actors || [
                {
                    role: '',
                    actors: '',
                },
            ]
        )
        setDetails(response.details)
        setPushkinCard(!!response.pushkinCard)
        setIsTop(response.isTop || 0)
        setRegistrationOpened(response.registrationOpened || 0)

        if (response?.new_photos && !!response.new_photos.length) {
            setNewPhotos((prev) => [
                ...prev,
                ...response.new_photos.map((obj) => ({
                    ...obj,
                    url: DOMAIN + obj.photo_url,
                })),
            ])
        }
    }, [eventID])

    useEffect(() => {
        if (!!eventID) {
            getEvent().then()
        } else {
            createEvent().then()
        }
        getEventCategoryList().then()
        getListPlaces().then()
    }, [eventID])

    const validFields = () => {
        const isCategory = categoryId !== undefined
        const isPlaceId = placeId !== undefined
        const isName = !!name.trim().length
        const isDates = !!dates && dates?.length !== 0
        const isContent = !!content[0].text.trim()
        const isPhotos = !!newphotos.length
        return (
            isCategory &&
            isPlaceId &&
            isName &&
            isDates &&
            isContent &&
            isPhotos
        )
    }

    const onSubmit = async () => {
        if (!validFields() || dates === null) {
            setError(true)
            return
        }

        let data = {
            categoryId,
            placeId,
            name,
            description,
            youtubeLink,
            buyTicketLink,
            content: JSON.stringify(content),
            interestingFacts: !!interestingFacts[0].description
                ? JSON.stringify(interestingFacts)
                : undefined,
            priceMin,
            priceMax,
            startAt: moment(dates[0]).format(),
            endAt: moment(dates[1]).format(),
            seances: correctFormat(seances),
            details,
            new_photos: newphotos.map((photo) => ({
                photo_id: photo.photo_id,
                type: photo.type,
                is_main: photo.is_main,
            })),
            actors: JSON.stringify(actors),
            pushkinCard: +pushkinCard,
            isTop,
            registrationOpened,
            tiflo,
        }

        const response = await EventService.updateEvent(createEvent_ID, data)

        if (response.success) {
            setCategoryId(undefined)
            setPlaceId(undefined)
            setName('')
            setDescription('')
            setYoutubeLink('')
            setBuyTicketLink('')
            setContent([
                {
                    id: new Date().getTime(),
                    text: '',
                    photos: [],
                },
            ])
            setInterestingFacts([{ description: '' }])
            setPriceMin(0)
            setPriceMax(0)
            setPictureData([])
            setPicturePreviewData([])
            setSeances([])
            setIsTop(0)
            setRegistrationOpened(0)

            if (publish) {
                const responsePublish = await EventService.publishEvent(
                    createEvent_ID
                )

                if (!responsePublish) {
                    message.warning('Событие создано, но не опубликовано ')
                } else {
                    message.success('Событие создано, и опубликовано ')
                }
            } else {
                const responseUnPublish = await EventService.unPublishEvent(
                    createEvent_ID
                )
                if (responseUnPublish) {
                    message.success('Событие изменено, и снято с публикации ')
                }
            }
        }

        nav.push(routes.get_events_list)
    }

    /**
     * создание нового события и получение его ИД
     */
    const createEvent = useCallback(async () => {
        const response = await EventService.createEvent()

        setCreateEvent_ID(response.id)
    }, [])

    /**
     * загрузка фото для события
     */
    const loadingPhoto = useCallback(
        async (photo: any, resourse: PhotoGaleryType) => {
            const data = {
                file: [photo],
            }

            let response: any

            if (resourse === PhotoGaleryType.MAIN) {
                response = await EventService.uploadPhoto(
                    createEvent_ID || 0,
                    data
                )

                if (response.success) {
                    setPictureData((prev) => [
                        ...prev,
                        response.data?.events.data,
                    ])
                    return response.data?.events.data
                } else {
                    message.error('Произошла ошибка при загрузке фотографии')
                }
            }

            if (resourse === PhotoGaleryType.PREVIEW) {
                response = await EventService.uploadPreviewPhoto(
                    createEvent_ID || 0,
                    data
                )

                if (response.success) {
                    setPicturePreviewData((prev) => [
                        ...prev,
                        response.data?.events.data,
                    ])
                } else {
                    message.error('Произошла ошибка при загрузке фотографии')
                }
            }

            if (resourse === PhotoGaleryType.PHOTO_LIST) {
                const { image, type, is_main } = photo

                const isMobile = isChecked(type)
                const isEmtyType = !!newphotos
                    ? newphotos.find((photo) => photo.type === type)
                    : false

                response = await LoadFiles(image)

                if (response.success) {
                    const { data } = response
                    if (!isMobile && isEmtyType) {
                        setNewPhotos((prev) =>
                            prev.map((item) =>
                                item.type === type
                                    ? {
                                          url: DOMAIN + data.path,
                                          type,
                                          photo_id: data.id,
                                          is_main,
                                      }
                                    : item
                            )
                        )
                    } else {
                        if (newphotos) {
                            setNewPhotos((prev) =>
                                isMainPhoto([
                                    ...prev,
                                    {
                                        url: DOMAIN + data.path,
                                        type,
                                        photo_id: data.id,
                                        is_main,
                                    },
                                ])
                            )
                        } else {
                            setNewPhotos(
                                isMainPhoto([
                                    {
                                        url: DOMAIN + data.path,
                                        type,
                                        photo_id: data.id,
                                        is_main,
                                    },
                                ])
                            )
                        }
                    }
                } else {
                    message.error('Произошла ошибка при загрузке фотографии')
                }
            }
        },
        [createEvent_ID, newphotos]
    )

    const correctFormat = (seances: SeanceType[]) => {
        const res = seances.map((s: SeanceType) => {
            return {
                startAt: s.startAt,
                endAt: s.endAt,
            }
        })
        return JSON.stringify(res)
    }

    useEffect(() => {
        setTimeout(() => {
            setError(false)
        }, 5000)
    }, [isError])

    /**
     * Получение списка категорий
     */
    const getEventCategoryList = async () => {
        const list = await CategoryEvent.getListEventCategories(1, 10, 1000)
        setEventList(list.data)
    }

    /**
     *  опции для выбора категории
     */
    const optionsList = useMemo(() => {
        return eventList.map((item) => {
            return {
                label: item.name,
                value: item.id,
            }
        })
    }, [eventList])

    /**
     * Опции для выбора места проведения
     */
    const optionsPlaceList = useMemo(() => {
        return locationList.map((item) => {
            return {
                label: item.name,
                value: item.id,
            }
        })
    }, [locationList])

    /**
     * получаем список мест
     * @returns
     */
    const getListPlaces = async () => {
        const list = await Location.getListLocation(1, 10, 1000)

        setLocationList(list.data)
        return list
    }

    const addedNewDate = (date: any, rangeTime: any[]) => {
        //@ts-ignore
        setCurrentSeanseDate(null)

        setSchedule([])

        setSeances(
            produce((draft) => {
                draft.push({
                    id: new Date().getTime(),
                    startAt: moment({
                        year: moment(schedule[0]).year(),
                        month: moment(schedule[0]).month(),
                        day: moment(schedule[0]).date(),
                        hour: moment(schedule[0]).hour(),
                        minute: moment(schedule[0]).minute(),
                    }).format(),
                    endAt: moment({
                        year: moment(schedule[1]).year(),
                        month: moment(schedule[1]).month(),
                        day: moment(schedule[1]).date(),
                        hour: moment(schedule[1]).hour(),
                        minute: moment(schedule[1]).minute(),
                    }).format(),
                })
            })
        )
    }

    const addNewIntrestingFacts = () => {
        setInterestingFacts(
            produce((draft) => {
                draft.push({ description: '' })
            })
        )
    }

    const closeInput = (index: any) => {
        if (interestingFacts.length === 1) {
            setInterestingFacts(
                produce((draft) => {
                    draft[index] = { description: '' }
                })
            )
            return
        }
        setInterestingFacts(
            produce((draft) => {
                draft.splice(index, 1)
            })
        )
    }

    const styleError = {
        border: isError ? '1px solid red' : '0px solid red',
    }

    /**
     * удаление фото
     * @param id фото ид
     * @param sourse откуда удаляем
     * @returns
     */
    const deletePhoto = async (id: number, sourse: PhotoGaleryType) => {
        let result: boolean
        if (sourse === PhotoGaleryType.MAIN) {
            result = await EventService.removePhoto(id)

            if (result) {
                setPictureData((prev) => {
                    return prev.filter((item) => item.id !== id)
                })
                return result
            } else {
                message.error(
                    'Что-то пошло не так, не получилось удалить фотографию'
                )
                return false
            }
        } else if (sourse === PhotoGaleryType.PREVIEW) {
            result = await EventService.removePreviewPhoto(id)

            if (result) {
                setPicturePreviewData((prev) => {
                    return prev.filter((item) => item.id !== id)
                })
            } else {
                message.error(
                    'Что-то пошло не так, не получилось удалить фотографию'
                )
            }
        } else {
            setNewPhotos((prev) =>
                isMainPhoto(prev.filter((item) => item.photo_id !== id))
            )
        }
    }

    /**
     * изменние интересных фактов
     */
    const onChangeInteresting = (e: string, index: number) => {
        setInterestingFacts(
            produce((draft) => {
                draft[index].description = e
            })
        )
    }

    /**
     * изменяем дату
     */
    const onChangeDate = (dates: any) => {
        if (Array.isArray(dates)) {
            setDates(dates)
        } else {
        }
    }
    /**
     * преобразуем в объект Moment
     * @param date
     * @returns массив с датами МОМЕНТ
     */

    const getMoment = (date: { startAt: string; endAt: string }) => {
        if (date) {
            return [moment(date.startAt), moment(date.endAt)]
        }
        return null
    }

    /**
     * Добавляем нового актера
     */
    const addNewActors = () => {
        setActors(
            produce((draft) => {
                draft.push({
                    role: '',
                    actors: '',
                })
            })
        )
    }
    /**
     * Удаление актера
     */
    const ondeleteActors = (ind: number) => {
        setActors((prev) => prev.filter((_, index) => index !== ind))
    }

    const changePriceMin = (e: number) => {
        let priceMin = e
        if (priceMin < 0) {
            priceMin = 0
        }

        setPriceMin(priceMin)
    }

    const changePriceMax = (e: number) => {
        let priceMax = e
        if (priceMax < 0) {
            priceMax = 0
        }

        setPriceMax(priceMax)
    }

    const setOnSchedule = (value: any) => {
        setCurrentSeanseDate(value[0])
        setSchedule(value)
    }

    return (
        <>
            <Title level={2}>
                {eventID ? 'Редактирование события' : 'Создание события'}
            </Title>

            {!!event?.kassy_id && (
                <Title level={4}>
                    Редактирование события - получено от КАССЫ.РУ
                </Title>
            )}

            {isError ? (
                <Alert
                    className={style.alert}
                    message="Все поля обязательны к заполнению"
                    type="error"
                />
            ) : null}

            <div className={style.selectWrapper} style={styleError}>
                <h2 className={style.title}>Категория</h2>
                <Select
                    showSearch
                    filterOption={(input, option) =>
                        (option?.label ?? '')
                            .toLowerCase()
                            .includes(input.toLowerCase())
                    }
                    optionFilterProp="children"
                    options={optionsList}
                    style={{ width: '100%' }}
                    value={categoryId}
                    placeholder="Выбрать категорию"
                    onChange={(id: number) => setCategoryId(id)}
                />
            </div>
            <div className={style.selectWrapper} style={styleError}>
                <h2 className={style.title}>Место проведения</h2>
                <Select
                    showSearch
                    filterOption={(input, option) =>
                        (option?.label ?? '')
                            .toLowerCase()
                            .includes(input.toLowerCase())
                    }
                    optionFilterProp="children"
                    style={{ width: '100%' }}
                    value={placeId}
                    options={optionsPlaceList}
                    placeholder="Выбрать место проведения"
                    onChange={(id: number) => setPlaceId(id)}
                />
            </div>

            <TitleAndDefinition
                isError={isError}
                firstValue={name}
                textAreaValue={description}
                firstTitle={'Название события'}
                textAreaTitle={'Описание события'}
                firstPlaceholder={true}
                textAreaPlaceholder={true}
                firstPlaceholderTitle={'Введите название события'}
                textAreaPlaceholderTitle={'Введите описание события'}
                onChangeFirst={(value) => setName(value)}
                onChangeTextArea={(value) => setDescription(value)}
                excludeErrorInputs={['textarea']}
            />
            <TitleAndDefinition
                isError={isError}
                firstValue={youtubeLink}
                secondValue={buyTicketLink}
                firstTitle={'Ссылка на YouTube'}
                secondTitle={'Ссылка на покупку билета'}
                firstPlaceholder={true}
                secondPlaceholder={true}
                firstPlaceholderTitle={'Введите ссылку на YouTube'}
                secondPlaceholderTitle={'Введите ссылку на покупку билета'}
                textAreaPlaceholderTitle={'Введите содержание'}
                onChangeFirst={(value) => setYoutubeLink(value)}
                onChangeSecond={(value) => setBuyTicketLink(value)}
                onChangeTextArea={(value) => setContent(value)}
                excludeErrorInputs={['first']}
            />
            <div style={styleError}>
                <TextEditor
                    value={
                        content || [
                            {
                                id: new Date().getTime(),
                                text: '',
                                photos: [],
                            },
                        ]
                    }
                    onChange={(data: Array<TextEditorType>) => setContent(data)}
                    onDeletePhoto={async (id: number) =>
                        await deletePhoto(id, PhotoGaleryType.MAIN)
                    }
                    key={content.length}
                    onUploadPhotos={async (data) =>
                        await loadingPhoto(data, PhotoGaleryType.MAIN)
                    }
                />
            </div>
            <div className={style.inputContainer} style={{}}>
                <h2 className={style.title}>Детали</h2>
                <div className={style.container}>
                    <div className={style.inputBlock}>
                        <div className={style.textBlock}>
                            <JoditEditor
                                config={config as any}
                                onChange={
                                    // setDetails
                                    (e) => {
                                        setDetails(e)
                                    }
                                }
                                value={details}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <Collapse accordion>
                <Panel header="Состав" key="actors">
                    <div className={style.seanceBlock}>
                        <div className={style.linksWrapper}>
                            <Button
                                style={{ borderRadius: '50%' }}
                                onClick={addNewActors}
                                type="primary"
                                className={style.btn}
                                icon={<PlusOutlined />}
                            />
                            <List
                                style={{ border: 'none', width: '100%' }}
                                size="default"
                                bordered
                                dataSource={actors || []}
                                renderItem={(item, index) => {
                                    return (
                                        <div
                                            className={style.inputFactsWrapper}
                                        >
                                            <List.Item
                                                style={{
                                                    width: '100%',
                                                    justifyContent: 'flex-end',
                                                    padding: 0,
                                                    margin: 0,
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        flex: 1,
                                                    }}
                                                >
                                                    <TitleAndDefinition
                                                        isSeanseStyle={true}
                                                        firstValue={
                                                            actors[index].role
                                                        }
                                                        firstPlaceholderTitle="Роль"
                                                        onChangeFirst={(
                                                            value
                                                        ) =>
                                                            setActors(
                                                                produce(
                                                                    (draft) => {
                                                                        draft[
                                                                            index
                                                                        ].role =
                                                                            value
                                                                    }
                                                                )
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        flex: 3,
                                                        marginLeft: '10px',
                                                    }}
                                                >
                                                    <TitleAndDefinition
                                                        isSeanseStyle={true}
                                                        firstValue={
                                                            actors[index].actors
                                                        }
                                                        firstPlaceholderTitle="Актерский состав"
                                                        onChangeFirst={(
                                                            value
                                                        ) =>
                                                            setActors(
                                                                produce(
                                                                    (draft) => {
                                                                        draft[
                                                                            index
                                                                        ].actors =
                                                                            value
                                                                    }
                                                                )
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        width: '40px',
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                    }}
                                                >
                                                    {actors.length > 1 && (
                                                        <CloseOutlined
                                                            onClick={() =>
                                                                ondeleteActors(
                                                                    index
                                                                )
                                                            }
                                                            className={
                                                                style.closeIcon
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </List.Item>
                                        </div>
                                    )
                                }}
                            />
                        </div>
                    </div>
                </Panel>
                <Panel header="Интересные факты" key="facts">
                    <div className={style.linksWrapper}>
                        <Button
                            style={{ borderRadius: '50%' }}
                            onClick={addNewIntrestingFacts}
                            type="primary"
                            className={style.btn}
                            icon={<PlusOutlined />}
                        />
                        <List
                            style={{ border: 'none', width: '100%' }}
                            size="default"
                            bordered
                            dataSource={interestingFacts}
                            renderItem={(item, index) => {
                                return (
                                    <div className={style.inputFactsWrapper}>
                                        <List.Item
                                            style={{
                                                width: '100%',
                                                justifyContent: 'flex-end',
                                                padding: 0,
                                                margin: 0,
                                            }}
                                        >
                                            <TitleAndDefinition
                                                isSeanseStyle={true}
                                                firstValue={item.description}
                                                firstPlaceholderTitle="Факт"
                                                onChangeFirst={(e) =>
                                                    onChangeInteresting(
                                                        e,
                                                        index
                                                    )
                                                }
                                            />
                                        </List.Item>
                                        <CloseOutlined
                                            onClick={() => closeInput(index)}
                                            className={style.closeIcon}
                                        />
                                    </div>
                                )
                            }}
                        />
                    </div>
                </Panel>
            </Collapse>

            {!!event?.kassy_image && (
                <>
                    <div className={style.photoKassyBlock}>
                        <p>Фото от КАССЫ.РУ</p>

                        <img
                            className={style.photoKassy}
                            src={`${event?.kassy_image}`}
                            alt="modal"
                        />

                        <Button
                            onClick={() =>
                                downloadImage(event.kassy_image!, 'kassy_image')
                            }
                        >
                            Скачать фото
                        </Button>
                    </div>
                </>
            )}

            <div className={style.seanceBlock}></div>
            {!!pictureData.length && (
                <div style={styleError}>
                    <PhotoGallery
                        title={'Фото события'}
                        data={pictureData}
                        onUpload={(value) =>
                            loadingPhoto(value, PhotoGaleryType.MAIN)
                        }
                        isCrop={true}
                        onDelete={(id) => deletePhoto(id, PhotoGaleryType.MAIN)}
                    />
                </div>
            )}

            {!!picturePreviewData.length && (
                <div style={styleError}>
                    <PhotoGallery
                        title={'Фото для превью и для верхнего слайдера'}
                        data={picturePreviewData}
                        onUpload={(value) =>
                            loadingPhoto(value, PhotoGaleryType.PREVIEW)
                        }
                        isCrop={true}
                        onDelete={(id) =>
                            deletePhoto(id, PhotoGaleryType.PREVIEW)
                        }
                    />
                </div>
            )}
            <div style={styleError}>
                <PhotoArea
                    data={newphotos}
                    onSubmit={(value) =>
                        loadingPhoto(value, PhotoGaleryType.PHOTO_LIST)
                    }
                    sizes={photoSizes}
                    onSelectMain={handleSelectMainPhoto}
                    onDelete={(id) =>
                        deletePhoto(id, PhotoGaleryType.PHOTO_LIST)
                    }
                />
            </div>
            <div className={style.priceWrapper}>
                <h2 className={style.title}>Цена</h2>
                <InputNumber
                    value={priceMin}
                    onChange={changePriceMin as any}
                    placeholder={'Минимальная'}
                />
                <span> - </span>
                <InputNumber
                    value={priceMax}
                    onChange={changePriceMax as any}
                    placeholder={'Максимальная'}
                />
            </div>
            <div style={styleError} className={style.dateRange}>
                <h2 className={style.title}>Дата начала и окончания</h2>
                <ConfigProvider locale={locale}>
                    <RangePicker
                        //@ts-ignore
                        value={dates}
                        placeholder={['Дата начала', 'Дата окончания']}
                        onChange={onChangeDate}
                    />
                </ConfigProvider>
            </div>
            <h2 className={style.title}>Сеансы</h2>
            <div className={style.seanceInner}>
                <div>
                    <ConfigProvider locale={locale}>
                        <RangePicker
                            format={formatTime}
                            placeholder={['Начало', 'Окончание']}
                            //@ts-ignore
                            value={schedule !== null ? schedule : null}
                            onChange={setOnSchedule}
                            showTime
                        />
                        <Button
                            disabled={
                                currentSeanseDate === undefined ||
                                currentSeanseDate === null ||
                                schedule.length === 0
                            }
                            onClick={() =>
                                addedNewDate(currentSeanseDate, schedule)
                            }
                            className={style.btnSeanse}
                        >
                            Создать сеанс
                        </Button>
                    </ConfigProvider>
                </div>
                <div className={style.seanceRightItem}>
                    <List
                        size="large"
                        bordered
                        dataSource={seances}
                        renderItem={(item) => {
                            return (
                                <div className={style.seanceItem}>
                                    <List.Item>
                                        <span className={style.seanceSpan}>
                                            Дата:{' '}
                                        </span>
                                        {moment(item.startAt).format('LL')}
                                        <br />

                                        <span className={style.seanceSpan}>
                                            Интервал:{' '}
                                        </span>
                                        {moment(item.startAt).day() ===
                                            moment(item.endAt).day() && (
                                            <span>
                                                {moment(item.startAt).format(
                                                    'LT'
                                                )}{' '}
                                                -{' '}
                                                {moment(item.endAt).format(
                                                    'LT'
                                                )}
                                            </span>
                                        )}

                                        {moment(item.startAt).day() !==
                                            moment(item.endAt).day() && (
                                            <span>
                                                {moment(item.startAt).format(
                                                    'LLL'
                                                )}{' '}
                                                -{' '}
                                                {moment(item.endAt).format(
                                                    'LLL'
                                                )}
                                            </span>
                                        )}
                                    </List.Item>

                                    <CloseOutlined
                                        onClick={() =>
                                            setSeances(
                                                produce((draft) => {
                                                    return draft.filter(
                                                        (elem) =>
                                                            elem.id !== item.id
                                                    )
                                                })
                                            )
                                        }
                                        width={'20px'}
                                        className={style.closeIcon}
                                    />
                                </div>
                            )
                        }}
                    />
                </div>
            </div>
            <div className={style.priceWrapper}>
                <Checkbox
                    checked={pushkinCard}
                    onChange={setPushkinCard.bind(null, !pushkinCard)}
                >
                    <h2 className={style.title}>Пушкинская карта</h2>
                </Checkbox>
            </div>

            <div className={style.priceWrapper}>
                <Checkbox
                    checked={!!tiflo}
                    onChange={(e) => {
                        setTiflo(e.target.checked ? 1 : 0)
                    }}
                >
                    <h2 className={style.title}>Тифлокомментирование</h2>
                </Checkbox>
            </div>

            <div className={style.priceWrapper}>
                <Checkbox
                    checked={!!isTop}
                    onChange={(e) => setIsTop(e.target.checked ? 1 : 0)}
                >
                    <h2 className={style.title}>Топовое место</h2>
                </Checkbox>
            </div>
            <div className={style.priceWrapper}>
                <Checkbox
                    checked={!!registrationOpened}
                    onChange={(e) =>
                        setRegistrationOpened(e.target.checked ? 1 : 0)
                    }
                >
                    <h2 className={style.title}>
                        Открыть регистрацию на мероприятие
                    </h2>
                </Checkbox>
            </div>
            <SaveBtnBlock
                onSave={onSubmit}
                onPublish={setPublish}
                isPublish={publish}
                hasID={!!eventID}
            />
        </>
    )
}

export default CreateEvent
