import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from './baseQuery'

let baseUrl = ''

if (process.env.REACT_APP_API_URL) {
    baseUrl = process.env.REACT_APP_API_URL
}

export const baseApi = createApi({
    baseQuery: axiosBaseQuery({
        baseUrl,
    }),
    reducerPath: 'baseApi',
    tagTypes: ['categories-cash-ru', 'categories-places-ru'],
    endpoints: (builder) => ({}),
})
